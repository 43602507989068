import React from 'react';
import Icon from '@mdi/react';
import { mdiFilePdf } from '@mdi/js';
import { List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/header';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { siaDocuments, siaFuente, siaUpdatedAt } from '../../utils/transparencia-difusion/constants';
import { useStyles } from '../../utils/transparencia-difusion/styles';
import { useGlobalStyles } from '../../utils/styles';
import { escala_gris } from '../../utils/constants';

const Sia = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title='Sistema Institucional de Archivos' />

      <div className={globalClasses.container}>
        <Header title='Sistema Institucional de Archivos' />

        <div className={globalClasses.content}>
          <Typography>Aquí puedes descargar los documentos normativos que rigen la gestión documental y administración de archivos del Instituto de Seguridad Social del Estado de Tabasco:</Typography>

          <List className={classes.list}>
            {siaDocuments.map((document, index) => (
              <a key={index} href={document.file} target="_blank" rel="noopener noreferrer">
                <ListItem button>
                  <ListItemAvatar>
                    <Icon path={mdiFilePdf} size={1.7} color={escala_gris ? '#767576' : '#e54e5d'} />
                  </ListItemAvatar>
                  <ListItemText primary={document.name} secondary={document.size} />
                </ListItem>
              </a>
            ))}
          </List>

          <UpdatedInfo fuente={siaFuente} updatedAt={siaUpdatedAt} />
        </div>
      </div>
    </Layout>
  );
};

export default Sia;